label {
  font-weight: 500;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: hidden;
  font-weight: 600 !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color: rgb(232, 235, 247);
}

.super-app-theme--header {
  background-color: rgb(232, 235, 247);
}

.button-color {
  background-color: "rgb(35, 57, 93)";
  color: "white";
}
