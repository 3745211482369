.super-app-theme--header{
    background-color :rgb(232, 235, 247);
    /* font-weight: 700 !important; */
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif    */
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    overflow: hidden;
    font-weight: 600 !important;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.MuiDataGrid-columnHeaderWrapper {
  background-color:rgb(232, 235, 247);
}
