html, body {
    height: 100%;
   }
@media only screen and (min-width : 1200px) {
    .container { max-width: none; }
   }
.parent{
    display:flex;
}
button {
    margin-bottom:5px;
    margin-right:5px;
    text-align: center;
    outline: none;
    border: none;
    border-radius: 15px;
    background-color:transparent;
}
button:hover {
    background-color: lightgrey;
}
button:active:enabled {
    transform: translateY(4px);
}
#container{
    width:100%;
    height: 50vh;
}

