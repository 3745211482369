.blinking-text {
    animation: blink 1s linear infinite;
    font-size: 12px;
    font-weight: 700;
    margin-top: 5px;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    40% {
        opacity: 2;
    }

    60% {
        opacity: 2;
    }

    80% {
        opacity: 3;
    }

    100% {
        opacity: 0;
    }
}

.blinking-text-div {
    background-color: #ffbf00;
    border-radius: 5px;
    width: 600px;
    text-align: center;
    margin-left: 10px;
}