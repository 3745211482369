 .header {
    background-color: rgb(35, 57, 93);
    color: white;
    border-bottom: solid 1px #ccc;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(204, 204, 204);
    }
  
  .navbar-brand {
      color: white !important;
  }

  