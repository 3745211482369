* {
    margin: 0px;
    padding: 0px;
    border: 0px;
}

html, body {
    height: 100%;
}

body {
    color: #4C4C4C;
    font-family: "HelveticaNeue", "Helvetica", "Arial", sans-serif;
    /* font-size: 0.9em; */
    line-height: 1.2;
    display: flex;
    flex-direction: column;
}

#background {
    position: fixed; 
    top:0; 
    left:0; 
    z-index: -10; 
    background: #F6F6F6; 
    width: 100%; 
    height: 100%;
}

header {
    margin: 0;
    font-size: 14px;
    padding: 5px 0px 2px 10px;
    background-color: #FFF;
    border-bottom: 2px solid #DDDDDD;
}

header.supervisor {
    justify-content: space-between;
    display: flex;
}

footer img {
    height: 40px;
    margin-left: 10px;
    float: right;
    margin: 0px 20px;
}

footer {
    /* margin-top: 50px;
    padding-top: 10px;*/
    border-top: 2px solid #DDDDDD;
    background: #FFF;
    height: 48px;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
}

#loading {
    color: #DDDDDD;
}

main {
    margin: auto;
    padding: 40px 15px 40px 15px;
    margin-top: 20px;
    width: 70%;
    min-width: 800px;
    text-align: center;
    border: 1px solid #CBCBCB;
    /* border-bottom: 5px solid #DDDDDD; */
    /* border-radius: 1em 1em; */
    background: #FFF;
    overflow: hidden;

    /* box-shadow: 7px 7px #DDDDDD; */
}

p {
    margin: 12px;
}


.success {
    color: #0baf4a;
}

.fail {
    color: #f54141e3;
}

/* Font awesmoe modifiers */
.enlarge {
    font-size: 2em;
    vertical-align: middle;
}

.hidden {
    display: none;
}

.sink-more {
    margin-top:40px;
}

/* Buttons */
button {
    border-radius: 5px 5px;
    padding: 15px 32px;
    text-align: center;
    font-weight: 600;
    font-size: 1em;
    cursor: pointer;
}

.wiz-btn-info {
    background-color: #2a60c8;
    color: white;
    border: 1px solid #2455a3;
}

.wiz-btn-info:hover {
    background-color: #2a60c8;
}

.right {
    float: right;
}

.left {
    float: left;
}

/* Modal */
.adjust-text {
   /* margin-top: -100px; */
    color: #2a60c8;
    font-size-adjust: unset;
}


/* wiz-progress */
.wiz-progress-bar {
    color: #4C4C4C;
    counter-reset: step;
    width: 950px;
    height: 60px;
    margin: auto; 
    margin-bottom: 50px;
}

.wiz-progress-bar li {
    list-style-type: none;
    width: 33%;
    float: left;
    text-transform: uppercase;
    text-emphasis: 1;
    text-align: center;
    position: relative;
}

.wiz-progress-bar li::before{
    width: 30px;
    height: 30px;
    content: counter(step);
    line-height: 30px;
    counter-increment: step;
    display: block;
    background-color: #fff;
    border: 2px solid #DDDDDD;
    border-radius: 50%;
    margin: 0 auto 10px auto;
}

.wiz-progress-bar li:after {
    width: 100%;
    height: 4px;
    content: '';
    position: absolute;
    background-color: #DDDDDD;
    top: 15px;
    left: -50%;
    z-index: -1;
}

.wiz-progress-bar li:first-child:after {
    content: none;
}

.wiz-progress-bar li.current::before {
    border: 5px solid;
}

.wiz-progress-bar li::before,.wiz-progress-bar li::after {
    -webkit-box-sizing: initial;
    -moz-box-sizing: border-box;
    box-sizing: content-box !important;
}

.wiz-progress-bar li.active {
    color: #4C4C4C;
}

.wiz-progress-bar li.active::before {
    color: #ff4f1f;
    border-color: #ff4f1f;    
    content: "\f00c";
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;
}


.wiz-progress-bar li.active + li:after {
    background-color: #DDDDDD;
}

.title {
    margin: 25px;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: #2a60c8;
    font-family: "Lato", "HelveticaNeue", "Helvetica", "Arial", sans-serif;
}

/* Message-box */
.message {
    width: 700px;
    margin: auto;
    border: 1px solid #DDDDDD;
    border-bottom: 3px solid #DDD;
}

.message-title{
    padding: 16px;
    color: white;
    font-size: 1em;
    font-weight: bold;
    text-align: left;
    background-color: #2a60c8; 
}

.message-content {
    background-color: #fff;
    padding: 16px;
    padding-left: 32px;
    text-align: left;
}

.full-height {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wiz-main {
    width: 74.5%;
}

.wiz-content {
    flex: 1 0 auto !important;
    font-size: 14.4px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 20px;
}